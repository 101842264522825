<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col
          cols="8"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nome"
            :rules="[required]"
            :label="$t('DepositoForm.nome')"
            required
            prepend-icon="mdi-account-edit"
          />
        </v-col>

        <v-col
          cols="4"
          sm="6"
        >
          <v-switch
            v-model="objEditar.ativo"
            :label="$t('padrao_ativo')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>

        <v-col
          cols="12"
        >
          <v-textarea
            v-model="objEditar.localizacao"
            outlined
            :label="$t('DepositoForm.localizacao')"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-alert
          v-model="alertValidacao"
          dismissible
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '../services/DepositosService'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import { mapState } from 'vuex'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      empresaClienteModel: null,
      objPadrao: {
        nome: '',
        localizacao: '',
        ativo: true,
        id: 0,
        idEmpresaCliente: null
      },
      empresaClienteModelId: null,
      objEditar: {
        nome: '',
        localizacao: '',
        ativo: true,
        id: 0,
        idEmpresaCliente: null
      },
      required: value => !!value || 'Campo Obrigatório',
      isEditting: false,
      error: null,
      validForm: false,
      alertValidacao: false
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      mensagemPerguntaRemocao () {
        return this.$i18n.t('padrao_deseja_remover_registro')
      },
      tituloPagina () {
        return this.$i18n.t('DepositoForm.tituloPagina')
      }

    },
    watch: {
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      traduzir (value) {
        try {
          return this.$t(value)
        } catch (error) {
          return value
        }
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.buscar()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover o registrro:\n ->' + error
          })
        }
      },
      closeDialog () {
        this.buscar()
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: this.$t('padrao_sucesso'),
                  dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                })
                this.buscar()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      buscar () {
        this.$router.push('/depositos')
      },
      inserir (registro, continuar) {
        try {
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (continuar) {
                this.editar(response.data.id)
              } else {
                this.buscar()
              }
            }
          })
            .catch(error => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Ocorreu um erro ao inserir um fornecedor:\n ->' + error
              })
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um fornecedor:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then(registro => {
            this.objEditar = registro.data
            this.isEditting = true
            this.empresaClienteModel = this.objEditar.EmpresaCliente
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
