import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('depositos', param)
  },
  insert (deposito) {
    return Api().post('deposito', deposito)
  },
  update (deposito) {
    return Api().put('deposito', deposito)
  },
  delete (deposito) {
    return Api().delete(`deposito?id=${deposito.id}`, deposito)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('depositosParam', param)
  },
  findById (deposito) {
    const url = `deposito/${deposito.id}`
    return Api().get(url)
  },
  buscaDepositosPorParam (param, idEmpresaCliente) {
    return Api().get(`depositosPorParam?idEmpresaCliente=${idEmpresaCliente}&param=${param}`)
  }

}
